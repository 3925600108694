/*=========================================\
	$SOMMAIRE
\=========================================*/
/*
 * SOMMAIRE		Vous êtes en train de le lire
 * GENERIC
 * PROMPTS 			Chrome frame & Browse Happy
 * SCAFFOLD
 * ALERTS
 */


/*=========================================\
	$GENERIC
\=========================================*/

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
* {
	.box-sizing(border-box);
}
*:before,
*:after {
	.box-sizing(border-box);
}

// Body reset
html {
	font-size: 10px;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

::selection {
	background: @brand-primary;
	color:#fff;
	text-shadow: none;
}

hr {
	margin-top:    @line-height-computed;
	margin-bottom: @line-height-computed;
	border: 0;
	border-top: 1px solid @hr-border;
}

/*
 * Remove the gap between images and the bottom of their containers: h5bp.com/i/440
 */

img {
	vertical-align: middle;
	max-width: 100%;
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
	cursor: pointer;
}

/*------------------------------------*\
    $SCAFFOLD
\*------------------------------------*/

a{
	color: @link-color;
	text-decoration: none;
	&:hover{
		color: @link-hover-color;
		text-decoration: underline;
	}
}

article,.cke_editable{
	img[style*="left"],
	img.left,
	figure[style*="left"],
	figure.left{
		max-width :50%;
		margin:0 1em .5em 0;
	}

	img[style*="right"],
	img.right,
	figure[style*="right"],
	figure.right{
		max-width :50%;
		margin:0 0 .5em 1em;
	}

	img,figure{
		max-width:100%;
	}
	figure{
		display: table;
		margin: 0 auto;
	}
	
	img,figure{
		&:not([style*="float"]){
			margin:0 auto;
		}
	}
}

figure{margin:0;}

abbr{
	cursor : help;
}

del,s{
	.strike;
}

.small,small{
	.small;
}

.caret{
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid\9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

.mark, mark {
	padding: .2em;
	color: #000;
	background-color: #fcf8e3;
}

blockquote{
	position: relative;
	margin 		: 0 0 1.5em;
	padding-left: 1.5em;
	border-left : 5px solid #eee;
	footer,small,.small {
		&:after { content: ''; }
		&:before {
			content: '\2014 \00A0'; // nbsp, em dash
		}
	}
}

q{
	&:before {content: open-quote;}
	&:after {content: close-quote;}
}
/*=========================================\
	$Language-specific quote
\=========================================*/
// http://en.wikipedia.org/wiki/International_variation_in_quotation_marks

:lang(af){quotes:'\201E' '\201D' '\201A' '\2019';}
:lang(ak){font-family:Lucida,"DejaVu Sans",inherit;}
:lang(ar){font-family:Tahoma 12,Nazli,KacstOne,"DejaVu Sans",inherit;}
:lang(bg){quotes:'\201E' '\201C' '\201A' '\2018';}
:lang(bn){font-family:FreeSans,MuktiNarrow,Vrinda,inherit;font-size:1.1em;line-height:1.4em;}
:lang(cs){quotes:'\201E' '\201C' '\201A' '\2018';}
:lang(da){quotes:'\00BB' '\00AB' '\203A' '\2039';}
:lang(de){quotes:'\201E' '\201C' '\201A' '\2018';}
:lang(el){font-family:"DejaVu Sans",inherit;quotes:'\00AB' '\00BB' '\2039' '\203A';}
:lang(en-GB){quotes:'\2018' '\2019' '\201C' '\201D';}
:lang(es){quotes:'\00AB' '\00BB' '\2039' '\203A';}
:lang(fa){font-family:Terafik,Traffic,Roya,Nazli,Nazanin,sans;font-size:1.5em;}
:lang(fi){quotes:'\201D' '\201D' '\2019' '\2019';}
:lang(fr){quotes:'\ab\2005' '\2005\bb' '\2039\2005' '\2005\203a';}
:lang(hr){quotes:'\00BB' '\00AB' '\203A' '\2039';}
:lang(is){quotes:'\201E' '\201C' '\201A' '\2018';}
:lang(ja){font-size:1.1em;}
:lang(km){font-family:"Khmer OS System","Khmer OS","Khmer Kampongtrach","CDT Khmer",inherit;line-height:2em;}
:lang(ko){font-size:1.1em;}
:lang(lt){quotes:'\201E' '\201C' '\201A' '\2018';}
:lang(nl){quotes:'\201E' '\201D' '\201A' '\2019';}
:lang(pl){quotes:'\201E' '\201D' '\201A' '\2019';}
:lang(ro){quotes:'\201E' '\201C' '\201A' '\2018';}
:lang(sk){quotes:'\201E' '\201C' '\201A' '\2018';}
:lang(sq){quotes:'\00AB' '\00BB' '\2039' '\203A';}
:lang(sr){quotes:'\201E' '\201C' '\201A' '\2018';}
:lang(sv){quotes:'\201D' '\201D' '\2019' '\2019';}
:lang(tr){quotes:'\00AB' '\00BB' '\2039' '\203A';}
:lang(ru){quotes:'\00AB' '\00BB' '\201E' '\201C';}
:lang(vi){font-family:"Lucida Grande","Vu Phu Tho","DejaVu Sans",inherit;}
:lang(vi) a:hover,:lang(vi) a:active{text-decoration:none;color:#606047;}
:lang(zh){font-size:1.5em;}

pre,code {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

/*=========================================\
	$SR-ONLY
\=========================================*/

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}

/*================================
=            $PRINT-P            =
================================*/

@media @print{
	article p a:after {
		content: " (" attr(href) ") ";
	}
	p{
		widows : 3;
		orphans: 3;
	}
}



