/* ==========================================================================
   AddOn to normalize.less
   ========================================================================== */

/*
 * stop Chrome yellowing input boxes
 */
input, textarea, select, button{outline: none;}
*:focus { outline:none;}

/*
 * Radio buttons have square border behind them in IE8-IE9
 */

.lt-ie10 input[type=radio]{
	border: 0;
}
.lt-ie10 input[type=checkbox]{
	border: 0;
}

/*
 * Google map patch (UI deformed, caused img { max-width: 100% })
 */
 
 .gm-style img { max-width: none; }
 .gm-style label { width: auto; display: inline; }