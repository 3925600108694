/*=========================================\
	$SOMMAIRE
\=========================================*/
/*
 * SOMMAIRE		Vous êtes en train de le lire
 * TABLE_RWD
 * IFRAME_RWD
 * IMAGES_RESPONSIVE
 */

/*=========================================\
	$TABLE_RWD
\=========================================*/

.table-rwd{
	overflow-x: auto;
	max-width: 100%;
	width:100%;
	table{
		width: 100%;
		max-width: 100%;
	}
}

/*=========================================\
	$IFRAME_RWD
\=========================================*/

.iframe-rwd  {
	position: relative;
	padding-bottom: 65.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}
.iframe-rwd iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



