/*=================================
=            $SOMMAIRE            =
=================================*/

/*
 * SOMMAIRE		Vous êtes en train de le lire
 * GENERIC
 * REQUIRED
 * FORMS
 * INPUT_GROUP
 * RADIO_CHECKBOX
 */

/*=========================================\
	$NORMALIZE FORM CONTROLS
\=========================================*/

.placeholder(@input-color-placeholder);

// Reset fonts for relevant elements
input,
button,
select,
textarea {
	font-family : inherit;
	font-size   : inherit;
	line-height : inherit;
}

select{
	-webkit-appearance: none; // safari reset
}

// Search inputs in iOS
//
// This overrides the extra rounded corners on search inputs in iOS so that our
// `.form-control` class can properly style them. Note that this cannot simply
// be added to `.form-control` as it's not specific enough. For details, see
// https://github.com/twbs/bootstrap/issues/11586.
input[type="search"] {
	.box-sizing(border-box);
	-webkit-appearance : none;
}

// Make range inputs behave like textual form controls
input[type="range"] {
	display : block;
	width   : 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
	height : auto;
}

// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	// Default
	outline        : thin dotted;
	// WebKit
	outline        : 5px auto -webkit-focus-ring-color;
	outline-offset : -2px;
}

// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned. As a workaround, we
// set a pixel line-height that matches the given height of the input, but only
// for Safari. See https://bugs.webkit.org/show_bug.cgi?id=139848
//
// Note that as of 8.3, iOS doesn't support `datetime` or `week`.

@media screen and (-webkit-min-device-pixel-ratio : 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		&.form-control {
			line-height : @input-height-base;
		}
	}
}

/*=========================================\
	$REQUIRED
\=========================================*/

/* Need helper.js : Use required attr form input to apply on label */
.required {
	&:after {
		color    : #e32;
		content  : ' *';
		display  : inline;
		position : absolute;
	}
}

/*=========================================\
    $LABEL
\=========================================*/

label {
	display       : inline-block;
	max-width     : 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
	margin-bottom : .3em;
	font-weight   : bold;
}

/*=========================================\
	$FIELDSET
\=========================================*/

fieldset {
	padding   : @padding-base-vertical @padding-base-horizontal;
	margin    : @line-height-computed 0;
	// Chrome and Firefox set a `min-width: min-content;` on fieldsets,
	// so we reset that to ensure it behaves more like a standard block element.
	// See https://github.com/twbs/bootstrap/issues/12359.
	min-width : 0;
}

legend {
	display     : block;
	padding     : 0 (@padding-base-horizontal * .5);
	font-size   : (@font-size-base * 1.5);
	line-height : inherit;
}

/*=========================================\
	$FORM-CONTROLS
\=========================================*/

.form-group {
	margin-top    : .5em;
	margin-bottom : .5em;
}

.form-control {
	display          : block;
	width            : 100%;
	padding          : @padding-base-vertical @padding-base-horizontal;
	
	line-height      : @line-height-base;
	color            : @input-color;
	background-color : @input-bg;
	background-image : none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border           : 1px solid @input-border-color;
	border-radius    : @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
	
	// Unstyle the caret on `<select>`s in IE10+.
	&::-ms-expand {
		border           : 0;
		background-color : transparent;
	}
	
	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		background-color : @input-bg-disabled;
		opacity          : 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
	}
	
	&[disabled],
	fieldset[disabled] & {
		cursor : @cursor-disabled;
	}
	
	// Reset height for `textarea`s
	textarea& {
		height     : auto;
		resize     : vertical; // Allow only vertical resizing of textareas.
		min-height : 6em;
	}
}

select.form-control {
	&:focus::-ms-value {
		// Suppress the nested default white text on blue background highlight given to
		// the selected option text when the (still closed) <select> receives focus
		// in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
		// match the appearance of the native widget.
		// See https://github.com/twbs/bootstrap/issues/19398.
		color: @input-color;
		background-color: @input-bg;
	}
}

/*====================================
=            $INPUT_GROUP            =
====================================*/

.input-group {
	position : relative;
	display  : flex;
	width    : 100%;
	.form-control {
		// Ensure that the input is always above the *appended* addon button for
		// proper border colors.
		position      : relative;
		z-index       : 2;
		flex: 1 1 auto;
		// Add width 1% and flex-basis auto to ensure that button will not wrap out
		// the column. Applies to IE Edge+ and Firefox. Chrome do not require this.
		//width: 1%;
		margin-bottom: 0;
		//min-width     : 0;
		&:not(:last-child){
			margin-right: -1px;
		}
		&:not(:first-child){
			margin-left: -1px;
		}
	}
	.btn{
		height :100%;
	}
}

.input-group-btn{
	&:not(:last-child){
		& >.btn,
		& >.btn-group{
			margin-right: -1px;
		}
	}
	&:not(:first-child){
		& >.btn,
		& >.btn-group{
			z-index: 2;
			margin-left: -1px;
		}
	}
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
	&:not(:first-child):not(:last-child) {
		border-radius : 0;
	}
}

.input-group-addon,
.input-group-btn {
	//flex: 0 0 auto;
	white-space    : nowrap;
	vertical-align : middle; // Match the inputs
	button {
		margin : 0;
	}
}

//
// Text input groups
//

.input-group-addon {
	position         : relative;
	white-space      : nowrap;
	//padding          : @padding-base-y @padding-base-x;
	margin-bottom    : 0; // Allow use of <label> elements by overriding our default margin-bottom
	font-weight      : normal;
	line-height      : @line-height-base;
	color            : @input-color;
	text-align       : center;
	background-color : @input-group-addon-bg;
	background-image : none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border           : 1px solid @input-border-color;
	border-radius    : @input-border-radius;
	
	// Nuke default margins from checkboxes and radios to vertically center within.
	input[type="radio"],
	input[type="checkbox"] {
		margin-top : 0;
	}
	// scss-lint:enable QualifyingElement
}

//
// Reset rounded corners
//

.ResetRoundedCorners(@input-border-radius) when (@input-border-radius > 0){
	.input-group .form-control:not(:last-child),
	.input-group-addon:not(:last-child),
	.input-group-btn:not(:last-child) > .btn,
	.input-group-btn:not(:last-child) > .btn-group > .btn,
	.input-group-btn:not(:last-child) > .dropdown-toggle,
	.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
	.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
		border-top-right-radius    : 0;
		border-bottom-right-radius : 0;
	}
	
	.input-group .form-control:not(:first-child),
	.input-group-addon:not(:first-child),
	.input-group-btn:not(:first-child) > .btn,
	.input-group-btn:not(:first-child) > .btn-group > .btn,
	.input-group-btn:not(:first-child) > .dropdown-toggle,
	.input-group-btn:not(:last-child) > .btn:not(:first-child),
	.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
		border-top-left-radius    : 0;
		border-bottom-left-radius : 0;
	}
}

.ResetRoundedCorners(@input-border-radius);

.input-group-addon:not(:last-child) {
	border-right : 0;
}

.form-control + .input-group-addon:not(:first-child) {
	border-left : 0;
}

/*========================================
=            $RADIO_CHECKBOX             =
========================================*/

//.radio,.checkbox{
//	.user-select(none);
//	margin-bottom: 1em;
//	position:relative;
//	display:block;
//	margin-left: 0;
//	&.inline{
//		label{
//			display: inline-block;
//			padding-right: .5em;
//			margin-bottom: 0;
//			vertical-align: middle;
//		}
//	}
//	label{
//		display:block;
//		cursor: pointer;
//		margin-bottom: .5em;
//	}
//	input[type=radio],input[type=checkbox]{
//		cursor: pointer;
//	}
//}




