/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: 'FontAwesome';
  src: url('@{fa-font-path}/fontawesome-webfont.woff2?v=@{fa-version}') format('woff2'),
       url('@{fa-font-path}/fontawesome-webfont.woff?v=@{fa-version}') format('woff'),
       url('@{fa-font-path}/fontawesome-webfont.ttf?v=4.7.0') format('truetype');
	font-weight: normal;
  font-style: normal;
}