/*=========================================\
	$BUTTONS
\=========================================*/

.btn{
	display          :inline-block;
	margin-bottom    :0; // For input.btn
	text-align       :center;
	vertical-align   :middle;
	touch-action     :manipulation;
	cursor           :pointer;
	background-image :none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border           :1px solid transparent;
	white-space      :nowrap;
	padding          :@padding-base-vertical @padding-base-horizontal;
	//font-size        :1em;
	line-height      :@line-height-base;
	.user-select(none);

	&:hover{
		text-decoration:none;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] &{
		opacity:.5;
		cursor :@cursor-disabled;
	}

	a&{
		&.disabled,
		fieldset[disabled] &{
			pointer-events :none; // Future-proof disabling of clicks on `<a>` elements
		}
	}

	&&-block{
		display :block;
		width : 100%;
		& + &{
			margin-top: 5px;
		}
	}
}
