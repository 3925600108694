// Text style
.text-center    { .align(center); }
.text-left      { .align(left); }
.text-right     { .align(right); }
.text-justify   { .align(justify); }
.text-upper     { .upper; }
.text-lower     { .lower; }
.text-capitalize{ .capitalize; }
.text-nowrap    { .nowrap; }
.text-strike    { .strike; }
.text-underline { .underline; }
.text-hide      { .text-hide;}
.text-overflow  { .text-overflow; }
// Font weight
.text-lighter   { .fw(1); }
.text-light     { .fw(2); }
.text-regular   { .fw(3); }
.text-bold      { .fw(4); }
.text-bolder    { .fw(5); }
//Images
.img-responsive { .rwd;}
.img-circle     { .circle;}
.img-rounded    { .rounded;}
// Listes
.list-unstyled  { .list-unstyled; }
.list-inline    { .list-inline; }
// Clearing
.clearfix       { .clearfix; }
.clear          { .clear; }
// Floats
.pull-left      { .left; }
.pull-right     { .right; }
//Shadows
.z1             { .shadow(1,#000); }
.z2             { .shadow(2,#000); }
.z3             { .shadow(3,#000); }
.z4             { .shadow(4,#000); }
.z5             { .shadow(5,#000); }
//Divers
.ir             { .ir; }
.center-block   { .center; }
.wrapper        { .wrapper; }
.lead           { .lead; }
.small          { .small; }
.contract       { .contract; }
.expand         { .expand; }
.equal          { .equal; }

/*=========================================\
	$TEXT-MIXINS
\=========================================*/

.align(@alignement) {text-align :@alignement;}
.upper() { text-transform :uppercase; }
.lower() { text-transform :lowercase; }
.capitalize(){text-transform :capitalize;}
.nowrap() { white-space :nowrap; }
.strike() { text-decoration :line-through; }
.underline() { text-decoration :underline; }

.text-hide(){
	font: ~"0/0" a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-overflow() {
	overflow      :hidden;
	text-overflow :ellipsis;
	white-space   :nowrap;
}

/*=========================================\
	$FONT-WEIGHT
\=========================================*/

// Look variable -> config.less
// @debug_weight_mixin: false;
// @fonts-less: "font-face";
// @fonts-path: "../fonts";
.fw(@i: 3){
	font-weight :normal; // default
	& when (@debug_weight_mixin = true){
		&:after{ content :"@{i}"; }
	}
	& when (@i = 1){  font-weight :100;  }
	& when (@i = 2){  font-weight :200;  }
	& when (@i = 3){  font-weight :normal;  }
	& when (@i = 4){  font-weight :bold;  }
	& when (@i = 5){  font-weight :900;  }
}

/*=========================================\
	$IMAGES-MIXINS
\=========================================*/

.rwd(){
	display: block;
	width: 100%;
	height: auto;
}
.circle() { border-radius :50%; }
.rounded() { border-radius :6px; }

/*=========================================\
	$LIST-MIXINS
\=========================================*/

.list-unstyled(){
	padding-left: 0;
	list-style: none;
}

.list-inline(){
	padding-left: 0;
	margin-left:.35em;
	list-style: none;
	li{
		display: inline-block;
		padding-right: .35em;
		padding-left: .35em;
	}
}

/*=========================================\
	$CLEARING-MIXINS
\=========================================*/

// The micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix() {
	// BELLOW WAS A RULES FOR OLD IE. DISABLED (2016)
	//*zoom:1;
	
	&:before,
	&:after{
		content :" ";
		display :table;
	}
	&:after{ clear :both; }
	// Normalize_addon.css patch aliasing suite
	// -webkit-backface-visibility: visible!important;
}

.clear() { clear :both; }

/*=========================================\
	$FLOATS-MIXINS
\=========================================*/

.left() { float :left!important; }
.right() { float :right!important; }

/*=========================================\
	$IR | Image replacement
\=========================================*/

.ir() {
	font             :~"0/0" a;
	text-shadow      :none;
	color            :transparent;
	background-color :transparent;
	border           :0;
	overflow         :hidden;
}

/*=========================================\
	$CENTER
\=========================================*/

.center() {
	display      :block;
	margin-left  :auto;
	margin-right :auto;
}

/*=========================================\
	$WRAPPER
\=========================================*/

.wrapper() {
	width     :@max_wrap;
	max-width :100%;
}

/*=========================================\
	$SHADOW
\=========================================*/

.shadow(@level: 1, @color: #000) {
	& when (@level = 1){
		box-shadow :0 1px 3px fade(@color, 12), 0 1px 2px fade(@color, 24);
	}
	& when (@level = 2){
		box-shadow :0 3px 6px fade(@color, 16), 0 3px 6px fade(@color, 23);
	}
	& when (@level = 3){
		box-shadow :0 10px 20px fade(@color, 19), 0 6px 6px fade(@color, 23);
	}
	& when (@level = 4){
		box-shadow :0 14px 28px fade(@color, 25), 0 10px 10px fade(@color, 22);
	}
	& when (@level = 5){
		box-shadow :0 19px 38px fade(@color, 30), 0 15px 12px fade(@color, 22);
	}
}

/*=========================================\
	$LEAD
\=========================================*/

.lead() { font-size :140%; }

/*=========================================\
	$SMALL
\=========================================*/

.small() { font-size :85%; }

/*=========================================\
	$CONTRACT
\=========================================*/

.contract() {
	.nowrap;
	width :1%;
}

/*=========================================\
	$EXPAND
\=========================================*/

.expand() {
	display :block;
	width   :100%;
	height  :100%;
}

/*=========================================\
	$EQUAL
\=========================================*/

.equal() {
	display        :flex;
	flex-direction :column;
	
	flex           :1 1 auto;
	// Equivalent à
	//flex-grow: 1;
	//flex-shrink: 1;
	//flex-basis: auto;
	
	.equalheight{
		flex: 1 1 auto;
		flex-direction: column;
		height :100%;
	}
}

