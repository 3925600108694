/**
 * Say Hello to "Doctor Loveless 2 (10.05.16)"
 */

/*=========================================\
	$INIT
\=========================================*/

@import "imports/imports";
@import (inline) "plugin/jquery.tosrus.all.css";
@import (inline) "plugin/owl.theme.default.css";
@import (inline) "plugin/owl.carousel.css";
@import (inline) "plugin/owl.transitions.css";

/*=========================================\
	$VARS
\=========================================*/

// Edit & add HERE the Less variables (look imports/config.less)

//-------- Scaffold

@font-family-base : @mont, @sans-serif;
@font-size-base : 16px;
@line-height-base : 1.428571429; // 20/14
@max_wrap :1440px;
@body-color : #fff;


//-------- Colors

@brand-primary : lighten(#428bca, 6.5%); // Texte selection color and link color default use it
@brand-success : #5cb85c;
@brand-info : #5bc0de;
@brand-warning : #f0ad4e;
@brand-danger : #d9534f;
@rouge: #ff0f2a;
@gris: #464646;
@nav: #3c3c3c;


//-------- Grid Flex

@max_wrap :1300px;

@xl :~"only screen and (min-width: 1675px)";
@lg :~"only screen and (min-width: 1300px)";
@md :~"only screen and (min-width: 985px)";
@sm :~"only screen and (min-width: 650px)";

@max-xl :~"only screen and (max-width: 1675px)";
@max-lg :~"only screen and (max-width: 1300px)";
@max-md :~"only screen and (max-width: 985px)";
@max-sm :~"only screen and (max-width: 650px)";


.makegrid(xs);
@media @sm{.makegrid(sm);}
@media @md{.makegrid(md);}
@media @lg{.makegrid(lg);}
@media @xl{.makegrid(xl);}

/*=========================================\
	$FONTS
\=========================================*/

@import '@{fonts-less}/font_awesome/font-awesome.less';
@fa: FontAwesome;

 @import '@{fonts-less}/open_sans/open_sans.less';
 @osr: open_sans;

 @import '@{fonts-less}/montserrat/montserrat.less';
 @mont: montserrat;


/*=========================================\
	$SCAFFOLD
\=========================================*/

:root{
	font-family : @font-family-base;
	font-size   : @font-size-base;
	line-height : @line-height-base;
	color       : @body-color;
}

html{
	//.checker; // CSS/SEO diagnostic
	height: 100%;
	@media only screen and (max-width: @max_wrap){
		// Tap delay remover
		// <meta name="viewport" content="width=device-width, user-scalable=no">
		-ms-touch-action: manipulation;
		touch-action: manipulation;
	}
}

body {
	background: #606060;
	min-height: 100%;
	overflow-x: hidden;
	width :100%;
}

.noscroll{ position: fixed;}

.wrap {
	.wrapper;
	.center;
	padding:0 1em;
}
.rwd-only{@media @md{display:none!important}}
.desktop-only{@media @max-md{display:none!important}}

a {
	color : #fff;
	.upper;
	&:hover {
		text-decoration : underline;
		color : #fff;
		transition: all .2s ease;
	}
}
hr {
	width :100%;
	border : none;
	height :1.5em;
	background-color : @nav;
}
.btn {
	background-color : @rouge;
	color : #fff;
	padding :.5em;
	.upper;
	&:hover {
		transition: all .2s ease;
		opacity :.8;
		background-color : @gris;
		color : #fff;
	}
}
.fa {
	margin-right :.5em;
}

/*=========================================\
	$TITLES
\=========================================*/

.alpha(){
	font-size :1.7em;
	color : @rouge;
	.fw(4);
}
.beta(){
	font-size :1.2em;
	color : #fff;
}
.gamma(){
	font-size :1.1em;
	color : @rouge;
}

h1,.alpha{.alpha}
h2,.beta{.beta}
h3,.gamma{.gamma}

/*=========================================\
	$HEADER   
\=========================================*/
header {
	background-color : @gris;
	height :170px;
	.header-content {
		height :110px;
		display : flex;
		flex-direction :row;
		justify-content :space-between;
		align-items :center;
		.logo {
			display : flex;
			flex-direction :row;
			align-items :center;
			img {
				padding :2em 0;
			}
			&-content {
				display : flex;
				flex-direction :column;
				h1, h2 {
					margin :0;
					font-size :1.2em;
				}
				h2 {
					color : @rouge;
				}
			}
			&:hover {
				text-decoration : none;
				opacity :.8;
				transition: all .2s ease;
			}
		}
		p {
			margin-bottom :0;
			font-size :1.2em;
			&:last-child {
				margin-top :0;
			}
			.fa {
				color : @rouge;
				margin-right :.5em;
			}
			a {
				text-transform: none;
			}
		}
	}
	@media @max-md {
		height :100px;
		border-bottom :1px solid lighten(@nav, 20%);
		.z2;
		.header-content {
			flex-direction :column;
			align-items :flex-start;
			padding :1em 0;
			width :100%;
			p {
				font-size :.9em;
			}

			.logo {
				img {
					padding :0;
					width :20%;
				}
				h1 {
					font-size :1em;
				}
			}
			.coord {
				display : none;
			}
		}
	}
}

/*=========================================\
	$NAVICON
\=========================================*/
.navicon{
	.nowrap;
	position : absolute;
	top :2em;
	right :.7em;
	display : inline-block;
	font-size :13px;
	height :5em;
	width :4em;
	padding :.5em;
	cursor : pointer;
	span:first-child {
		top :.35em;
		position : relative;
		display : block;
		height :2px;
		width :100%;
		background : @rouge;
		margin :.4em 0;
		transition: all .1s linear;
		&:after,&:before {
			.rotate(0);
			transition : all .14s linear;
			position : absolute;
			top :-1em;
			right :0;
			content : '';
			display : inherit;
			width :inherit;
			height :inherit;
			background : @rouge;
		}
		&:before { top :1em; }
	}
	span + span {
		&:before,&:after {
			.animated(400,100);
			.fw(3);
			.upper;
			color : @rouge;
			display : block;
			width :100%;
			height :.1em;
			position : absolute;
			bottom :2em;
			left: .7em;
			font-size :.7em;
		}
		&:after {
			.fadeInUp;
			content : 'Menu';
			letter-spacing :.35em;
		}
		&:before {
			content:'Fermer';
			opacity :0;
			letter-spacing :.08em;
		}
	}
	@media @max-md {
		& + nav {
			z-index :100;
			transition: transform .134s ease-in-out  ;
			.transform(~'translateX(-170%)');
		}
		&.active-nav {
			position : fixed;
			& + nav {
				left :0;
				.transform(~'translateX(-5%)');
			}
			span:first-child {
				background-color : transparent;
				&:after,&:before {
					top :0;
					background-color : @rouge;
				}
				&:after { .rotate(45deg); top :-.05em;}
				&:before {.rotate(-45deg);}
			}
			span:last-child { opacity :0;}
			span + span {
				&:after {.fadeOutDown;}
				&:before {.fadeInUp;}
			}
		}
		&  nav {
			overflow-x : visible;
			&:after {
				.animated;
				pointer-events: none ;
				position : absolute;
				transition : opacity  .33s ease;
				content : '';
				height :0;
				width :100vw;
				top :0;
				left :0;
				z-index :99;
				opacity :0;
			}
		}
		&.active-nav + nav {
			&:after {
				height :100%;
				opacity :.8;
			}
		}
	}
}

@media @max-md {
	main,footer{ transition:transform .2s ease-in-out; }
	body.active-nav{
		main,footer{
			position : relative;
			.translate3d(20%,0   ,0);
			opacity :.5;
		}
	}
}
/*=========================================\
	$NAV
\=========================================*/
nav {
	background-color : @nav;
	.nav-content {
		display : flex;
		flex-direction :row;
		justify-content :space-between;
		align-items :center;

		ul.mainnav {
			padding :0;
			li {
				list-style : none;
				display : inline-block;
				padding :0 1em;
				position : relative;
				&.active {
					a {
						color : @rouge;
					}
					&:after {
						content: '';
						display: block;
						left: 0;
						opacity :1;
						width: 100%;
						height: 0.2em;
						background-color: @rouge;
					}
				}
				a {
					color : #fff;
					font-size :1.1em;
					.upper;
					&:hover {
						text-decoration : none;
						color : #fff;
					}
				}
				&:after {
					content: '';
					display: block;
					left: 0;
					width: 0;
					height: 0.2em;
					opacity :0;
					background-color: @rouge;
					transition: width 0.2s;
				}
				&:hover {
					&:after { width: 100%; opacity :1; }
					.subnav {
						opacity :1;
						.animated(300);
						.fadeInDown;
					}
				}
				.subnav {
					background-color : lighten(@gris, 10%);
					.z2;
					opacity :0;
					position : absolute;
					top :2em;
					left :0;
					z-index :5;
					ul {
						padding :0;
						margin :0;
						li {
							padding :1em 0;
							width :100%;
							&:after {
								display : none;
							}
							&:hover {
								background-color : @nav;
							}
							a {
								padding: 1em .5em;
								text-transform: none;
								.fw(3);
								font-size :.9em;
							}
						}
					}
					
				}
			}
		}
		.search {
			input {
				background-color : #212121;
				border: none;
				height :30px;
				padding-left :1em;
			}
			input[placeholder] {
				opacity :1;
				color : #fff !important;
				font-size :.8em !important;
			}
			button {
				border :none;
				background-color : transparent;
				.fa {
					color : #fff;
				}
			}
		}
	}
	@media @max-md {
		position : fixed;
		padding :0;
		top :100px;
		left :0;
		height :calc(~"100% - 100px");
		max-width: 20em;
		z-index :20;
		overflow : auto;
		.z2;
		background-color : @nav;
		.wrap { padding :0;}
		.nav-content { 
			padding-bottom :2em;
			.search {
				display : none;
			}
			ul.mainnav {
				display : flex;
				flex-direction: column;
				width :100%;
				a {
					color : #fff !important;
				}
				li{
					padding :0 !important;
					display : inline-block;
					width :100%;
					a {
						.align(left);
						.upper !important;
						.fw(3) !important;
						font-size :1.1em !important;
						text-transform : none;
						display : block;
						width :100%;
						padding :.7em 2em;
						border-bottom :.05em solid @rouge;
						&:hover { text-decoration : none; }
						&.link {
							.fa { display : none;}
						}
					}
					&:hover { opacity :1; }
					.subnav {
						opacity :1;
						.animated(200);
						display : block;
						.fadeInDown;
						.align(left);
						position : relative;
						left :0;
						top :0 !important;
						z-index :20;
						width :100%;
						margin :0;
						padding :0;
						padding-top :0 !important;
						ul {
							padding-left :1em;
							a {
								.fw(4) !important;
								font-size :.9em !important;
								color : @gris;
								border-bottom :1px solid @rouge;
							}
							li {
								display : inline;
								padding :0;
								margin :0;
								a {
									.fw(3) !important;
									text-transform : none !important;
									padding :.5em 0 .5em 2em;
									border-bottom :none;
								}
							}
						}
					}
				}
			}
		}

		@media only screen and (max-width: 596px){
			width :80%;
			max-width : initial;
		}
	}
}
/*=========================================\
	$HERO
\=========================================*/
.hero {
	.wrap {
		position : relative;
	}
	&-content {
		position : absolute;
		width :50%;
		height :10em;
		top :50%;
		left :25%;
		right :0;
		bottom :0;
		display : flex;
		align-items :center;
		justify-content :center;
		text-align : center;
		flex-direction :column;
		background-color : rgba(70, 70, 70, .8);
		color : #fff;
		z-index :2;
		padding :5em;
		.texte {
			p.beta {
				.upper;
			}
		}
		@media @max-md {
			top: 40%;
			padding :2em;
		}
	}
	.hero-slider {
		position : relative;
		.item {
			position : relative;
			&-content {
				position : absolute;
				top :5em;
				left :0;
				background-color : rgba(70, 70, 70, .8);
				z-index :1;
				padding :1em;
				h1, h3 {
					color : #fff;
					margin :0;
				}
				@media @max-md {
					top : 0;
				}
			}
		}
		.owl-dots {
			position : absolute;
			bottom :0;
			left :0;
			right :0;
			.owl-dot {
				span {
					width :20px;
					height :20px;
					background-color : @rouge;
					.z2;
				}
				&.active {
					span   {
						background-color : darken(@rouge, 10%);
					}
				}
			}
		}

	}
	&.photos {
		background-color : @gris;
		padding :1em;
	}
}

/*=========================================\
	$SLIDER
\=========================================*/
.slider-actus {
	background-color : @nav;
	padding :1em;
	position : relative;
	.item   {
		padding :0 3em;
		&-content {
			display : flex;
			flex-direction :row;
			align-items :center;
			.fa {
				font-size :4em;
				margin-right :.5em;
			}
			p {
				margin-bottom :0;
				&:nth-child(2) {
					margin-top :0;
				}
			}
			a {
				color : #fff;
				.upper;
				&:hover {
					color : @rouge;
					text-decoration: underline;
					transition: all .2s ease;
				}
			}
		}
	}
	.owl-dots { display : none; }
	.owl-nav {
		position :absolute;
		top :30%;
		width :100%;
		left :0;
		height :1em;
		.owl-prev, .owl-next {
			background-color : transparent;
			&:hover {
				background-color : transparent;
				.fa {
					color : @rouge;
					transition: all .2s ease;
				}
			}
		}
		.owl-prev { float : left; }
		.owl-next { float : right; }
	}
}

.about-slider {
	.item {
		padding :.5em;
	}
	.owl-dots { display : none; }
	.owl-nav {
		.owl-prev, .owl-next {
			background-color : @rouge;
			.fa {
				margin-right :0;
			}
			&:hover {
				background-color : @rouge;
				.fa {
					opacity :.8;
					transition: all .2s ease;
				}
			}
		}
	}
}
/*=========================================\
	$SECTION
\=========================================*/
.block-invert {
	background-color : @gris;
	padding :1em 0;
	.titre {
		padding :1em;
		h2, p {
			margin :0;
		}
		h2 {
			font-size :1.3em;
		}
		p {
			margin-top :1em;
			font-size :1.2em;
		}
	}
	& .block {
		padding :0 1em;
		.title {
			text-align : center !important;
		}
	}
	.block-content {
		hr {
			height :.5em;
			background-color : @rouge;
		}
	}
}

.block {
	&-content {
		padding :1em 2em 2em 2em;
		display : flex;
		align-items :center;
		align-content :center;
		&-img {
			position : relative;
			.hover {
				opacity :0;
				position : absolute;
				top :0;
				left :0;
				right :0;
				bottom :0;
				display : flex;
				align-items :center;
				justify-content :center;
				text-align : center;
				flex-direction :column;
				.fa {
					font-size :3em;
				}
			}
			&:hover {
				background-color : rgba(70, 70, 70, .8);
				img {
					opacity :.5;
				}
				.hover {
					opacity :1;
					z-index :1;
				}
			}
		}
		&-texte {
			h3 { color : #fff; }
			a { 
				text-decoration: underline;
				&:hover {
					opacity :.8;
					transition: all .2s ease;
					color : @rouge;
				}
			}
		}
		.btn {
			&:hover {
				background-color : lighten(@rouge, 10%);
			}
		}
	}
}
/*=========================================\
	$FORM
\=========================================*/
.contact {
	.btn {
		.center;
		}
}

.contact_btn {
	background-color : @nav;
	padding :1em 0;
	display : flex;
	flex-direction :column;
	align-items :center;
 }
/*=========================================\
	$IFRAME
\=========================================*/
.iframe-rwd {
	padding-bottom: 35.25% !important;
}
/*=========================================\
	$ARTICLE
\=========================================*/
.news {
	.block {
		padding :0 1em;
	}

	.paginate {
		&-wrap {
			display : flex;
			flex-direction :row;
			justify-content :center;
			align-items :center;
			ul {
				padding :0;
				list-style: none;
			}
			a {
				background-color : transparent;
				&.active {
					background-color : @rouge;
				}
			}
		}
	}
}
p {
	img {
		margin-right :1em;
	}
}
article {
	background-color : lighten(@gris, 20%);
	padding :1em;
	margin-bottom :1em;
	a {
		.fw(4);
		&:hover {
			color : @rouge;
		}
	}
	.header {
		h3 { margin: 0;}
		border-bottom :2px dotted @rouge;
		.infos {
			position : relative;
			width :100%;
			margin-bottom :.5em;
			color : #fff;
			time {
				font-size :.8em;
				.fw(4);
			}
			p {
				display : inline;
				padding-left :1em;
			}

		}
	}
}
/*=========================================\
	$PAGINATE
\=========================================*/

/*=========================================\
	$FOOTER
\=========================================*/
footer {
	background-color :@nav;
	.footer-content {
		display : flex;
		flex-direction :row;
		justify-content :space-between;
		align-items :center;
		.logo {
			display : flex;
			flex-direction :row;
			align-items :center;
			img {
				padding :2em 0;
			}
			&-content {
				display : flex;
				flex-direction :column;
				h1, h2 {
					margin :0;
					font-size :1.2em;
				}
				h2 {
					color : @rouge;
				}
			}
			&:hover {
				text-decoration : none;
				opacity :.8;
				transition: all .2s ease;
			}
		}
		.menu {
			ul {
				padding :0;
				margin :0;
				li {
					list-style: none;
					display : inline-block;
					padding-right :.5em;
					&:after {
						content : '-';
					}
					&:last-child {
						&:after {
							display : none;
						}
					}
					a {
						text-transform : none;
						padding-right :.5em;
						&:last-child {
							padding-right :0;
						}
					}
				}
			}
		}
	}
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////// END ////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
