
/* =========================================== */

// LIGHT (2)

@font-face {
    font-family: 'montserrat';
    src: url('@{fonts-path}/montserrat/montserrat-light.woff2') format('woff2'),
        url('@{fonts-path}/montserrat/montserrat-light.woff') format('woff'),
        url('@{fonts-path}/montserrat/montserrat-light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

/* =========================================== */

// REGULAR (3)

@font-face {
    font-family: 'montserrat';
    src: url('@{fonts-path}/montserrat/montserrat-regular.woff2') format('woff2'),
        url('@{fonts-path}/montserrat/montserrat-regular.woff') format('woff'),
        url('@{fonts-path}/montserrat/montserrat-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* =========================================== */

// BOLD (4)
@font-face {
	font-family: 'montserrat';
	src: url('@{fonts-path}/montserrat/montserrat-semibold.woff2') format('woff2'),
		url('@{fonts-path}/montserrat/montserrat-semibold.woff') format('woff'),
		url('@{fonts-path}/montserrat/montserrat-semibold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

// BOLDER (5)
@font-face {
	font-family: 'montserrat';
	src: url('@{fonts-path}/montserrat/montserrat-bold.woff2') format('woff2'),
	    url('@{fonts-path}/montserrat/montserrat-bold.woff') format('woff'),
		url('@{fonts-path}/montserrat/montserrat-bold.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

