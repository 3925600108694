// BASED on FlexBoxGrid (commit 6032a70 | on 22 Aug 2015)
// https://github.com/bassjobsen/flexboxgrid-less

/*=========================================\
	$VARIABLES
\=========================================*/

@half-gutter-width:		  0.5rem;
@gutter-compensation:	 -0.5rem;
@outer-margin:				2rem;
@grid-columns:             12;

@xl: ~"only screen and (min-width: 1675px)";
@lg: ~"only screen and (min-width: 1300px)";
@md: ~"only screen and (min-width: 985px)";
@sm: ~"only screen and (min-width: 650px)";

/*=========================================\
	$MIXINS
\=========================================*/

.row-reverse() {
	flex-direction: row-reverse;
	flex-wrap: wrap-reverse;
}

.col-reverse() {
	flex-direction: column-reverse;
	flex-wrap: wrap-reverse;
}

.makegrid(@class) {
	//[class^="col-@{class}"], [class*=" col-@{class}"],
	//[class^="col-@{class}-offset"], [class*=" col-@{class}-offset"] {
	[class*="col-@{class}"],
	[class*="col-@{class}-offset"] {
		box-sizing: border-box;
		flex: 0 0 auto;
		flex-direction: column;
		padding-right: @half-gutter-width;
		padding-left: @half-gutter-width;
	}

	.col-@{class} {
		flex-grow: 1;
		flex-basis: 0;
		max-width: 100%;
	}

	//[class*="col-@{class}"]:not([class*="@{class}-0"]) { display:initial; }
	[class*="@{class}-0"] { display:none; }

	.make-cols(@class);
	.make-offsets(@class);
	.make-position-classes(@class);
}

.make-column(@class; @col-number) {
	.col-@{class}-@{col-number} {
		flex-basis: ((100% / @grid-columns) * @col-number);
		max-width: ((100% / @grid-columns) * @col-number);
	}
}

.make-cols(@class; @col-number: @grid-columns) when (@col-number > 0) {
	.make-cols(@class; (@col-number - 1));
	.make-column(@class; @col-number);
}

.make-offset(@class; @col-number) {
	.col-@{class}-offset-@{col-number} {
		margin-left: ((100% / @grid-columns) * @col-number);
	}
}

.make-offsets(@class; @col-number: (@grid-columns - 1)) when (@col-number > 0) {
	.make-offsets(@class; (@col-number - 1));
	.make-offset(@class; @col-number);
}

.make-position-classes(@class) {

	.start-@{class} {
		justify-content: flex-start;
		text-align: start;
	}
	.center-@{class} {
		justify-content: center;
		text-align: center;
	}
	.end-@{class} {
		justify-content: flex-end;
		text-align: end;
	}
	.top-@{class} { align-items: flex-start; }
	.middle-@{class} { align-items: center; }
	.bottom-@{class} { align-items: flex-end; }
	.stretch-@{class} { align-items: stretch; }
	.around-@{class} { justify-content: space-around; }
	.between-@{class} { justify-content: space-between; }
	.first-@{class} { order: -1; }
	.last-@{class} { order: 1; }
}

/*=========================================\
	$GRID
\=========================================*/

.grid {
	padding-right: @outer-margin;
	padding-left: @outer-margin;
}
.row {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-right: @gutter-compensation;
	margin-left: @gutter-compensation;
}

.row.cell{
	margin-right: inherit;
	margin-left: inherit;
	[class*="col-"]{
		padding-right: inherit;
		padding-left: inherit;
	}
}

.row.gap{
	[class*="col-"]{
		padding-bottom: @half-gutter-width;
		padding-top: @half-gutter-width;
	}
}

.row.reverse {
	.row-reverse();
}
.col.reverse {
	.col-reverse();
}

/*=========================================\
	$SETUP
\=========================================*/

// -------------------> Copier les lignes commentées dans l'importeur (style.less).
//.makegrid(xs);
//@media @xl{.makegrid(@xl);}
//@media @lg{.makegrid(@lg);}
//@media @md{.makegrid(@md);}
//@media @sm{.makegrid(@sm);}